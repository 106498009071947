import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24"
  }, [
    _createElementVNode("path", {
      fill: "none",
      d: "M0 0h24v24H0z"
    }),
    _createElementVNode("path", {
      d: "M13 14h-2a9 9 0 0 0-7.97 4.81A10.14 10.14 0 0 1 3 18 10 10 0 0 1 13 8V3l10 8-10 8v-5z",
      fill: "currentColor"
    })
  ]))
}